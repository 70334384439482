const routes = {
  path: "/financiero",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
			props: true,
      component: () => import("./views/Index.vue"),
      meta: { requiresAuth: true, acceso: 'RENTABILIDAD' },
      children: [
        {
          path: "",
          name: "informeMensual",
          props: true,
          component: () => import("./components/InformeMensual.vue"),
          meta: { requiresAuth: true, acceso: 'RENTABILIDAD' }
        },
        {
          path: "/presupuesto",
          name: "presupuesto",
          props: true,
          component: () => import("./components/Presupuesto.vue"),
          meta: { requiresAuth: true, acceso: 'RENTABILIDAD' }
        },
        {
          path: "/sector",
          name: "sector",
          props: true,
          component: () => import("./components/Sector.vue"),
          meta: { requiresAuth: true, acceso: 'RENTABILIDAD' }
        }
      ]
    }
  ],
};

export default routes;
